import * as React from "react";
import { graphql } from "gatsby";
import { Seo } from "../components/Seo";
import { Header, Footer } from "../components/layout/index";
import {
  Hero,
  KeyFigures,
  Testimonials,
  Roles,
  References,
  Faq,
  BlogListing,
  Partners,
  Cta,
  Security,
} from "../components/sections/index";
import { urlFor } from "../utils";

const IndexPage = ({ data }) => {
  const page = (data || {}).mainPage;
  const coverPictureURL = (page.hero && urlFor(page.hero.image)) || undefined; // let SEO component define default image if hero has no image

  return (
    <>
      <Seo
        title="Solution de téléexpertise et de e-RCP"
        description="Omnidoc est une solution de téléexpertise et de e-RCP à destination des professionnels de santé et des organisations médicales qui souhaitent améliorer, sécuriser et valoriser les avis échangés."
        keywords="téléexpertise médicale rémunérée télé-expertise teleexpertise tele-expertise expertise acte avis télé-dermatologie télédermatologie télé-cardiologie télécardiologie télémédecine télé-médecine assurance maladie"
        image={coverPictureURL}
      />
      <Header />
      {page.hero && (
        <Hero
          title={page.hero.title}
          subtitle={"Solution de téléexpertise et de e-RCP"}
          accentTitle={page.hero.accentTitle}
          description={page.hero.description}
          image={page.hero.image}
          buttons={[
            {
              text: "S'inscrire",
              href: "https://app.omnidoc.fr/register",
            },
            {
              text: "Demander une démo",
              href: "/demander-une-demo",
            },
          ]}
        />
      )}
      {page.keyFiguresSection && (
        <KeyFigures
          header={page.keyFiguresSection.title}
          keyFigures={[
            { ...page.keyFiguresSection.keyFigure1, prefix: "+" },
            { ...page.keyFiguresSection.keyFigure2, prefix: "+" },
            { ...page.keyFiguresSection.keyFigure3, prefix: "+" },
          ]}
        />
      )}
      {page.rolesSection && (
        <Roles
          title={page.rolesSection.title}
          description={page.rolesSection.description}
          specialistsDescription={page.rolesSection.specialistsDescription}
          gpDescription={page.rolesSection.gpDescription}
          otherIndividualsDescription={
            page.rolesSection.otherIndividualsDescription
          }
          hospitalsDescription={page.rolesSection.hospitalsDescription}
          cptsEssDescription={page.rolesSection.cptsEssDescription}
          otherGroupsDescription={page.rolesSection.otherGroupsDescription}
        />
      )}
      {page.references && (
        <References
          header={page.references.title}
          references={page.references}
        />
      )}
      {page.testimonials && page.testimonials.length > 0 && (
        <Testimonials testimonials={page.testimonials} />
      )}
      {!!(page.blogListingSection && page.blogListingSection.length) && (
        <BlogListing blog_posts={page.blogListingSection} />
      )}
      {page.partners && (
        <Partners
          title={page.partners.title}
          description={page.partners.description}
          partners={page.partners}
        />
      )}
      {page.faqSection && (
        <Faq title={page.faqSection.title} faqs={page.faqSection.faqs} />
      )}
      {page.security && (
        <Security
          title={page.security.title}
          description={page.security.description}
          certifications={page.security.certifications}
        />
      )}
      {page.ctaBanner && (
        <Cta
          titleLine1={page.ctaBanner.titleLine1}
          titleLine2={page.ctaBanner.titleLine2}
          description={page.ctaBanner.description}
          buttonText={page.ctaBanner.buttonText}
          url={page.ctaBanner.url}
        />
      )}
      <Footer />
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPageQuery {
    mainPage: sanityMainPage(_id: { regex: "/(drafts.|)mainPage/" }) {
      hero {
        title
        accentTitle
        description
        image {
          ...Image
          alt
        }
      }
      rolesSection {
        title
        description
        specialistsDescription
        gpDescription
        otherIndividualsDescription
        hospitalsDescription
        cptsEssDescription
        otherGroupsDescription
      }
      references {
        title
        logos {
          ...Image
          alt
          url
        }
      }
      keyFiguresSection {
        title
        keyFigure1 {
          number
          label
        }
        keyFigure2 {
          number
          label
        }
        keyFigure3 {
          number
          label
        }
      }
      testimonials {
        quote
        authorName
        authorTitle
        image {
          ...Image
          alt
        }
      }
      partners {
        title
        description
        logos {
          ...Image
          alt
          url
        }
      }
      security {
        title
        description
        certifications {
          ...Image
          alt
        }
      }
      faqSection {
        title
        faqs {
          question
          answer
        }
      }
      ctaBanner {
        titleLine1
        titleLine2
        description
        buttonText
        url
      }
      blogListingSection {
        id
        title
        slug {
          current
        }
        body: _rawBody
        description
        publishedAt(formatString: "DD MMMM YYYY", locale: "fr")
        mainImage {
          ...ImageWithPreview
          alt
        }
      }
    }
  }
`;
